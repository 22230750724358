<template>
  <v-row class="px-3">
    <v-col
      cols="6"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="'Share term name'"
        :rules="{ required: true, max: 255 }"
      >
        <common-input-text-field
          v-model="internalValue.name"
          label="Share Term Name"
          color="primary"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
    <v-col cols="6">
      <validation-provider
        v-slot="{ errors }"
        :name="'Prefix'"
        :rules="{ required: true, max: 20 }"
      >
        <common-input-text-field
          v-model="internalValue.prefix"
          label="Prefix"
          color="primary"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
    <v-col
      cols="6"
      class="mb-n1"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="'Voting Power'"
        :rules="{ required: true, min_value: 0 }"
      >
        <common-input-text-field
          v-model="internalValue.voting_power"
          label="Voting Power"
          color="primary"
          type="number"
          :error-messages="errors"
          :hide-details="errors.length === 0"
          :hint="'Voting Power should be >= 0'"
          :persistent-hint="true"
        />
      </validation-provider>
    </v-col>
    <v-col
      cols="6"
      class="mb-n1"
    >
      <validation-provider
        v-slot="{ errors }"
        :name="'Board approval date'"
        :rules="{ required: true }"
      >
        <input-template-date
          v-model="boardApprovalDate"
          :mask="'##/##/####'"
          :error-messages="errors"
          :hide-details="errors.length === 0"
          :input-templ="{label: 'Board approval date', mandatory: true}"
          clearable
        />
      </validation-provider>
    </v-col>
    <v-col
      cols="6"
      class="mb-1"
    >
      <validation-provider
        v-if="showFinancingRound"
        v-slot="{ errors }"
        :name="'Financing round'"
        :rules="{ required: true }"
      >
        <common-input-select
          v-model="internalValue.financing_round_id"
          label="Financing Round"
          placeholder="Financing Round"
          :items="financingRounds"
          item-text="name"
          item-value="id"
          :error-messages="errors"
          :hide-details="errors.length === 0"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'

  export default {
    name: 'CommonShareTermForm',

    components: {
      InputTemplateDate,
    },

    props: {
      financingRounds: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
      showFinancingRound: {
        type: Boolean,
        default: true,
      },
    },

    data: () => ({
      boardApprovalDate: '',
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      internalValue: {
        handler (val) {
          if (val) {
            this.boardApprovalDate = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.internalValue.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },
  }
</script>
