<template>
  <common-trs-card class="pa-2 pb-0 mb-2">
    <validation-observer
      ref="observer"
      v-slot="{ valid }"
      class="ml-auto"
    >
      <v-card-text>
        <v-form>
          <v-row class="px-3">
            <v-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :name="'Share type'"
                :rules="{ required: true }"
              >
                <common-input-select
                  v-model="internalValue.type"
                  label="Share type"
                  placeholder="Select share type"
                  :items="CONSTS.SHARE_TERMS_TYPE"
                  item-text="name"
                  item-value="type"
                  :error-messages="errors"
                  :hide-details="errors.length === 0"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <div class="mx-0">
            <common-share-term-form
              v-if="internalValue.type == CONSTS.SHARE_TERM_TYPES.COMMON"
              v-model="internalValue"
              :financing-rounds="financingRounds"
            />
            <preferred-share-term-form
              v-if="internalValue.type == CONSTS.SHARE_TERM_TYPES.PREFERRED"
              v-model="internalValue"
              :financing-rounds="financingRounds"
              @update="updatePreferredTerm"
            />
          </div>
          <div class="mx-3">
            <notes-and-documents-form
              :id="shareTermId"
              :mode="mode"
              :is-submit="isSubmit"
              :internal-dialog="internalDialog"
              :type="CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS"
              @navToList="$emit('navToList', true)"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-row class="mx-4">
        <v-col>
          <v-divider />
          <div class="d-flex justify-space-between align-center pt-3">
            <common-trs-btn
              type="secondary"
              medium
              class="pull-right mr-2"
              text
              :to="{name: 'ShareTerms'}"
            >
              Cancel
            </common-trs-btn>
            <common-trs-btn
              type="primary"
              medium
              class="white--text pull-right"
              text
              :disabled="!valid"
              :loading="loading"
              @click="save"
            >
              Save
            </common-trs-btn>
          </div>
        </v-col>
      </v-row>
    </validation-observer>
  </common-trs-card>
</template>
<script>
  import CommonShareTermForm from '@/components/common/captable/components/forms/CommonShareTermForm'
  import PreferredShareTermForm from '@/components/common/captable/components/forms/PreferredShareTermForm'
  import NotesAndDocumentsForm from '@/components/common/captable/components/forms/NotesAndDocumentsForm.vue'
  export default {
    name: 'ShareTermForm',

    components: {
      CommonShareTermForm,
      PreferredShareTermForm,
      NotesAndDocumentsForm,
    },

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        default: () => {},
      },
      shareTermId: {
        type: String,
      },
      mode: {
        type: String,
        default: 'create',
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      preferredShareTermData: {},
      preferredShares: false,
      notesData: {},
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    created () {
      this.internalValue = { ...this.internalValue, type: this.CONSTS.SHARE_TERM_TYPES.PREFERRED }
    },
    methods: {
      save () {
        if (this.internalValue.type === this.CONSTS.SHARE_TERM_TYPES.COMMON) {
          delete this.internalValue.preferred_share_terms
        }
        this.clearData(this.internalValue)
        this.clearData(this.internalValue.preferred_share_terms)
        this.$emit('save', this.internalValue)
      },

      updatePreferredTerm (val) {
        this.internalValue = val
      },

      clearData (obj) {
        if (obj && Object.keys(obj) && Object.keys(obj).length > 0) {
          Object.keys(obj).forEach((key) => {
            if (obj[key] === null || obj[key] === undefined || (typeof obj[key] === 'object' && (!Object.keys(obj[key]) || Object.keys(obj[key]).length === 0))) {
              delete obj[key]
            } else if (typeof obj[key] === 'object') {
              this.clearData(obj[key])
            }
          })
        }
      },
    },
  }
</script>
