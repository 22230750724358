var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Share term name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Share Term Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.name),callback:function ($$v) {_vm.$set(_vm.internalValue, "name", $$v)},expression:"internalValue.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Prefix',"rules":{ required: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Prefix","color":"primary","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.prefix),callback:function ($$v) {_vm.$set(_vm.internalValue, "prefix", $$v)},expression:"internalValue.prefix"}})]}}])})],1),_c('v-col',{staticClass:"mb-n1",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Voting Power',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Voting Power","color":"primary","type":"number","error-messages":errors,"hide-details":errors.length === 0,"hint":'Voting Power should be >= 0',"persistent-hint":true},model:{value:(_vm.internalValue.voting_power),callback:function ($$v) {_vm.$set(_vm.internalValue, "voting_power", $$v)},expression:"internalValue.voting_power"}})]}}])})],1),_c('v-col',{staticClass:"mb-n1",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}])})],1),_c('v-col',{staticClass:"mb-1",attrs:{"cols":"6"}},[(_vm.showFinancingRound)?_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Financing Round","items":_vm.financingRounds,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.internalValue.financing_round_id),callback:function ($$v) {_vm.$set(_vm.internalValue, "financing_round_id", $$v)},expression:"internalValue.financing_round_id"}})]}}],null,false,4219389307)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }