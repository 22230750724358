<template>
  <div>
    <v-row>
      <v-col class="py-0">
        <common-share-term-form
          v-model="internalValue"
          :financing-rounds="financingRounds"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="internalValue.preferred_share_terms"
      class="px-3"
    >
      <v-col
      cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Conversion ratio'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="internalValue.preferred_share_terms.conversion_ratio"
            label="Conversion ratio"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :hint="'No. of common shares issued per share of preferred shares upon conversion (e.g. 1.0)'"
            :persistent-hint="true"
            :options="{ locale: 'en-US', precision: 2 }"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <liquidation-preference-form
          v-model="liquidationData"
          :liquidation-enabled="liquidationEnabled"
          @isLiquidatonEnabled="updateLiquidationFlag"
          @update="updateLiquidation"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CommonShareTermForm from '@/components/common/captable/components/forms/CommonShareTermForm'
  import LiquidationPreferenceForm from './LiquidationPreferenceForm'

  export default {
    name: 'PreferredShareTermForm',

    components: {
      CommonShareTermForm,
      LiquidationPreferenceForm,
    },

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      financingRounds: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    data: () => ({
      liquidationEnabled: false,
      liquidationData: {},
    }),

    computed: {

      internalValue: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      internalValue: {
        handler (val) {
          if (val) {
            try {
              if (this.internalValue.preferred_share_terms.liquidation_preference.multiple) { this.liquidationEnabled = true }
            } catch (e) {
              this.liquidationEnabled = false
            }
            this.internalValue.preferred_share_terms = this.internalValue.preferred_share_terms ? this.internalValue.preferred_share_terms : {}
            this.internalValue.preferred_share_terms.liquidation_preference = this.internalValue.preferred_share_terms.liquidation_preference ? this.internalValue.preferred_share_terms.liquidation_preference : this.internalValue.preferred_share_terms.liquidation_preference = { participating_preference: {} }
            this.liquidationData = this.internalValue.preferred_share_terms.liquidation_preference
          }
        },
        immediate: true,
      },
    },

    methods: {
      removeLiquidation () {
        delete this.internalValue.preferred_share_terms.liquidation_preference
      },

      updateLiquidationFlag (val) {
        this.liquidationEnabled = val
        if (!this.liquidationEnabled) {
          this.removeLiquidation()
        }
      },

      updateValue () {
        if (!this.liquidationEnabled) {
          this.removeLiquidation()
        }
        this.$emit('update', this.internalValue)
      },

      updateLiquidation (val) {
        this.internalValue.preferred_share_terms.liquidation_preference = val
        this.updateValue()
      },
    },
  }
</script>
