var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"px-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('common-input-checkbox',{staticClass:"mt-n3",attrs:{"label":"Liquidation Preferences"},model:{value:(_vm.isLiquidationPref),callback:function ($$v) {_vm.isLiquidationPref=$$v},expression:"isLiquidationPref"}})],1),(_vm.isLiquidationPref)?_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Dividends',"rules":{ required: false, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Dividends (Optional)","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"hint":'Declared but unpaid dividends per share',"persistent-hint":true,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.updateValue},model:{value:(_vm.value.dividends),callback:function ($$v) {_vm.$set(_vm.value, "dividends", $$v)},expression:"value.dividends"}})]}}],null,false,4039579640)})],1):_vm._e()],1),(_vm.isLiquidationPref)?_c('interest-form',{on:{"update":_vm.updateInterest},model:{value:(_vm.interestTerms),callback:function ($$v) {_vm.interestTerms=$$v},expression:"interestTerms"}}):_vm._e(),(_vm.isLiquidationPref)?_c('v-row',{staticClass:"px-3"},[_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Select priority',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Select priority","placeholder":"Select priority","items":_vm.CONSTS.INTREST_PRIORITY_TYPES,"item-text":"name","item-value":"type","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.previous_class),callback:function ($$v) {_vm.$set(_vm.value, "previous_class", $$v)},expression:"value.previous_class"}})]}}],null,false,4062134770)})],1),_c('v-col',{staticClass:"mb-n3",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Multiple',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Multiple","color":"primary","options":{ locale: 'en-US', precision:2 },"error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.multiple),callback:function ($$v) {_vm.$set(_vm.value, "multiple", $$v)},expression:"value.multiple"}})]}}],null,false,2159009167)})],1)],1):_vm._e(),(_vm.isLiquidationPref)?_c('v-row',{staticClass:"px-3 py-0"},[_c('v-col',{staticClass:"px-3 py-0",attrs:{"cols":"12"}},[_c('common-input-switch',{attrs:{"validate-on-blur":"","label":"Participating Preference"},model:{value:(_vm.isParticipatingPref),callback:function ($$v) {_vm.isParticipatingPref=$$v},expression:"isParticipatingPref"}})],1),(_vm.isParticipatingPref)?_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":'Participating Cap',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Participating Cap","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision:2 }},on:{"input":_vm.updateValue},model:{value:(_vm.internalValue.participating_preference.participating_cap),callback:function ($$v) {_vm.$set(_vm.internalValue.participating_preference, "participating_cap", $$v)},expression:"internalValue.participating_preference.participating_cap"}})]}}],null,false,1195144705)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }