<template>
  <div>
    <v-row class="px-3">
      <v-col cols="12">
        <common-input-checkbox
          v-model="isLiquidationPref"
          label="Liquidation Preferences"
          class="mt-n3"
        />
      </v-col>
      <v-col
        v-if="isLiquidationPref"
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Dividends'"
          :rules="{ required: false, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="value.dividends"
            label="Dividends (Optional)"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :hint="'Declared but unpaid dividends per share'"
            :persistent-hint="true"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <interest-form
      v-if="isLiquidationPref"
      v-model="interestTerms"
      @update="updateInterest"
    />

    <v-row
      v-if="isLiquidationPref"
      class="px-3"
    >
      <v-col
        cols="6"
        class="mb-n3"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Select priority'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.previous_class"
            label="Select priority"
            placeholder="Select priority"
            :items="CONSTS.INTREST_PRIORITY_TYPES"
            item-text="name"
            item-value="type"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
        class="mb-n3"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Multiple'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="value.multiple"
            label="Multiple"
            color="primary"
            :options="{ locale: 'en-US', precision:2 }"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row
      v-if="isLiquidationPref"
      class="px-3 py-0"
    >
      <v-col
        cols="12"
        class="px-3 py-0"
      >
        <common-input-switch
          v-model="isParticipatingPref"
          validate-on-blur
          label="Participating Preference"
        />
      </v-col>
      <v-col
        v-if="isParticipatingPref"
        cols="8"
      >
        <validation-provider

          v-slot="{ errors }"
          :name="'Participating Cap'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="internalValue.participating_preference.participating_cap"
            label="Participating Cap"
            color="primary"
            :error-messages="errors"
            :options="{ locale: 'en-US', precision:2 }"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import InterestForm from './InterestForm'

  export default {
    name: 'LiquidationPreferenceForm',
    components: {
      InterestForm,
    },

    props: {
      value: {
        type: Object,
        required: true,
        default: () => {},
      },
      liquidationEnabled: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      isParticipatingPref: false,
      interestTerms: {},
      hasInterest: false,
      hasParticiPartingPreference: false,
    }),

    computed: {
      internalValue: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      isLiquidationPref: {
        get () {
          return this.liquidationEnabled
        },
        set (val) {
          this.hasLiquidationPref(val)
        },
      },
    },

    watch: {
      internalValue: {
        handler (val) {
          if (val) {
            try {
              if (this.internalValue.participating_preference.participating_cap) { this.hasParticiPartingPreference = true }
            } catch (e) {
              this.hasParticiPartingPreference = false
            }
            this.isParticipatingPref = this.hasParticiPartingPreference
            this.interestTerms = this.internalValue.interest_terms || {}
            this.internalValue.participating_preference = this.internalValue.participating_preference || {}
          }
        },
      },
    },

    methods: {
      updateInterest (val) {
        if (val) {
          this.value.interest_terms = val
          this.value.include_interest = true
        } else {
          this.value.include_interest = false
          delete this.value.interest_terms
        }
      },

      hasLiquidationPref (val) {
        this.value.include_interest = false
        this.$emit('isLiquidatonEnabled', val)
      },

      updateValue () {
        this.$emit('update', this.internalValue)
      },
    },
  }
</script>
